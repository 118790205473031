
.about{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;  
    background: rgb(238,174,202);
background: radial-gradient(circle, rgb(21, 119, 126) 0%, rgba(148,187,233,1) 100%);

}
.aboutImg{
    width: 80%;
    height: 80%;
    
   
}
.aboutHead{
    font-size: 1rem;
    font-weight: bold;
    color: rgb(58, 4, 4);
    margin-bottom: 10px;
    font-weight: bold;
    text-align: center;
}
.aboutSpan{
    font-size: 2rem;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
    text-align: center;
}
.aboutSpan:hover{   
    color: rgb(200, 225, 197);
    transform: rotateX(360deg) scale(1.2);
    transition: all 1s ease-in-out;
    background: rgb(26, 19, 174);
    background: linear-gradient(66deg, rgb(16, 7, 192) 0%, rgb(100, 100, 196) 12%, rgb(36, 158, 183) 100%);
}
.aboutText{
    font-size: 1rem;
    color: rgb(58, 4, 4);
    margin-bottom: 10px;
    padding: 10px;
    text-align: right;
    border: 1px solid white;
    border-radius: 10px;

}
.aboutText h3{
    font-size: 1.5rem;
    font-weight: bold;
    color: rgb(58, 4, 4);
    margin-bottom: 10px;
    text-align: right;
}
.aboutText:hover{
    color: rgb(200, 225, 197);    
    transform: rotateX(45deg) scale(1.2);
    transition: all 0.5s ease-in-out;
    background: rgb(26, 19, 174);
    background: linear-gradient(66deg, rgb(56, 49, 178) 0%, rgb(128, 128, 168) 12%, rgba(0,212,255,1) 100%);
    
}

.aboutEmail{
    font-size: 20px;
    text-align: center;
    color: rgb(62, 11, 139);
    text-decoration: none;
    color: white;
}
.aboutEmail:hover{
    text-decoration: underline;
    font-size: large;
    color: rgb(200, 225, 197);
    
}